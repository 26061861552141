import * as React from "react"
import { useEffect } from "react";
import {
  Box, Flex, Heading, VStack, FormControl, FormLabel, Input, Button, useToast, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text, Divider, Grid, GridItem, HStack
} from "@chakra-ui/react";
import { useHistory, Link } from "react-router-dom";
import { useState } from "react";
import { addUser } from "../../api/UserService";

export function AddUser() {
  const toast = useToast();
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [gender, setGender] = useState("");

  // Clear the fields on initial render
  useEffect(() => {
    setUsername("");
    setPassword("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setAgeGroup("");
    setGender("");
  }, []);

  const saveUser = () => {
    addUser({
      username,
      password,
      firstName,
      lastName,
      email,
      phone,
      ageGroup,
      gender
    })
      .then(() => {
        toast({
          title: "User created successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        history.push("/users");
      })
      .catch((error: { message: any; }) => {
        toast({
          title: "Failed to create the user.",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <Box w="100%" h="90%" bg="background.secondary">
      {/* Breadcrumb Navigation */}
      <Flex w="100%" h="10%" pl={6} alignItems="center">
        <Breadcrumb color="gostation.500" fontWeight="bold" fontSize="lg">
          <BreadcrumbItem color="gostation.100">
            <BreadcrumbLink as={Link} to="/users">
              <Text>Users</Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem color="gostation.500" isCurrentPage>
            <BreadcrumbLink>
              <Text>Create User</Text>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </Flex>

      {/* Main Form */}
      <Flex w="96%" h="90%" minH="500px" ml={6} mr={6} justifyContent="center">
        <VStack spacing={4} w="100%">
          <Box bg="white" w="100%" p={4} alignItems="left">
            <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">User Information</Heading>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <GridItem>
                <FormControl id="username" isRequired>
                  <FormLabel fontSize="xs" color="gray">Username</FormLabel>
                  <Input
                    bg="#F9F9F9"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    size="md"
                    autoComplete="off"  
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="password" isRequired>
                  <FormLabel fontSize="xs" color="gray">Password</FormLabel>
                  <Input
                    bg="#F9F9F9"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size="md"
                    autoComplete="new-password"  // Disable autofill for password
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </Box>

          <Box bg="white" w="100%" p={4} alignItems="left">
            <Heading pb={3} pt={3} fontWeight="bold" size="sm" color="gostation.500">Personal Information</Heading>
            <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <GridItem>
                <FormControl id="firstName" isRequired>
                  <FormLabel fontSize="xs" color="gray">First Name</FormLabel>
                  <Input
                    bg="#F9F9F9"
                    placeholder="First name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    size="md"
                    autoComplete="off"
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="lastName" isRequired>
                  <FormLabel fontSize="xs" color="gray">Last Name</FormLabel>
                  <Input
                    bg="#F9F9F9"
                    placeholder="Last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    size="md"
                    autoComplete="off"
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="email" isRequired>
                  <FormLabel fontSize="xs" color="gray">Email</FormLabel>
                  <Input
                    bg="#F9F9F9"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    size="md"
                    autoComplete="off"
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="phone">
                  <FormLabel fontSize="xs" color="gray">Phone Number</FormLabel>
                  <Input
                    bg="#F9F9F9"
                    placeholder="Phone number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    size="md"
                    autoComplete="off"
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="ageGroup">
                  <FormLabel fontSize="xs" color="gray">Age Group</FormLabel>
                  <Input
                    bg="#F9F9F9"
                    placeholder="Age group"
                    value={ageGroup}
                    onChange={(e) => setAgeGroup(e.target.value)}
                    size="md"
                    autoComplete="off"
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <FormControl id="gender">
                  <FormLabel fontSize="xs" color="gray">Gender</FormLabel>
                  <Input
                    bg="#F9F9F9"
                    placeholder="Gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    size="md"
                    autoComplete="off"
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </Box>
        </VStack>
      </Flex>

      <Divider />
      <Flex justifyContent="center" w="96%" mt={4}>
        <HStack spacing={6}>
          <Button as={Link} to="/users" colorScheme="gostation" variant="outline" size="md">CANCEL</Button>
          <Button onClick={saveUser} colorScheme="gostation" variant="solid" size="md">SAVE</Button>
        </HStack>
      </Flex>
    </Box>
  );
}
