import {authApiHeader} from "./AuthHeader";
import {User} from "../types/UserTypes";
import { CancelToken } from "axios";
const axios = require('axios').default;

const API_URL = process.env.REACT_APP_API_URL + "/api/users";

//Users
export const getUserList = (params: any) => {
    return axios.get(API_URL, { headers: authApiHeader(), params});
};

export const searchUserListFiltered = (params: any, cancelToken: CancelToken) => {
    return axios.get(API_URL + "/searchFiltered", { headers: authApiHeader(), params, cancelToken });
};

export const addUser = (params: any) => {
    return axios.post(API_URL, params,{ headers: authApiHeader()});
};

export const getUser = (params: any) => {
    return axios.get(API_URL + "/findByUsername",{ headers: authApiHeader(), params});
};

export const changeStatus = (params: any) => {
    return axios.put(API_URL + "/changeStatus",null,{ headers: authApiHeader(), params});
};

export const promoCodesAvailable = (params: any) => {
    return axios.get(API_URL + "/promoCodesAvailable",{ headers: authApiHeader(), params});
};

export const assignPromoCodeUser = (params: any) => {
    return axios.post(API_URL + "/addPromoCodeAdmin", null,{ headers: authApiHeader(), params});
};
